import React from 'react'

export const Legend = (props) => {
  const { title, children } = props
  return(
    <span className="mr-2">
    {children}{' '}{title}
    </span>
  )
}

export default Legend