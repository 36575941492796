import React, { useEffect, useState, useRef } from 'react'
// import { Table } from "react-bootstrap";
import './jobseeker.scss'
import Header from '../../component/Header/Header';
// import { FcDownload } from "react-icons/fc";
// import ReactTable from "react-table";

import { fetchJobSeekerList } from "../../redux/JobSeekerList/jobSeekerListAction";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axios from 'axios'
import Paginations from "../../component/paginations/Paginations"
import moment from "moment-timezone";

const Job_seeker_list = (props) => {

  const anchorDownloadFileRef = useRef()
  const [downloadFileName, setDownloadFileName] = useState();
  const [downloadDataUriContent, setDownloadDataUriContent] = useState();
  const [showPerPage] = useState(3);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const dispatch = useDispatch();
  const { JobSeekerListData } = props;
  useEffect(() => {
    dispatch(fetchJobSeekerList());
  }, [dispatch]);

  const download = (file) => () => {
    // console.log("arg::::::::::",file);
    // // let url = new URL(file.target.href);
    // // console.log("file name::::::::::",url.pathname);
    // console.log(_replace(file,"/",""));
    // return _replace(file,"/","")
    // // return url.pathname;
    axios.post('http://localhost:3001/node/resumedownload', { file: file, responseType: 'arraybuffer' })
      .then(res => {
        console.log(res.data);
        let filedata = res.data;
        if (filedata) {
          // const buffer = window.self.atob(window.self.btoa(filedata));
          const buffer = window.btoa(unescape(encodeURIComponent(filedata)))
          const mimeString = "application/pdf";
          var ab = new ArrayBuffer(buffer.length);
          // create a view into the buffer
          var ia = new Uint8Array(ab);
          // set the bytes of the buffer to the correct values
          for (var i = 0; i < buffer.length; i++) {
            ia[i] = buffer.charCodeAt(i);
          }
          // write the ArrayBuffer to a blob, and you're done
          const blob = new Blob([ab], { type: mimeString });
          // eslint-disable-next-line no-restricted-globals
          const urlToDownload = self.URL.createObjectURL(blob);
          console.log("urlToDownload ::", urlToDownload);
          setDownloadFileName("resume")
          setDownloadDataUriContent(urlToDownload)
          console.log(anchorDownloadFileRef);
          if (anchorDownloadFileRef.current) {
            anchorDownloadFileRef.current.click()
          }
        }
      })
  }



  //******************Set Pagination Start and End Point********************
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end })
  };
  var Count = JobSeekerListData.jobSeekerListEntities.length;

  return JobSeekerListData.loading ? (
    <h2>Loading</h2>
  ) : JobSeekerListData.error ? (
    <h2>{JobSeekerListData.error}</h2>
  ) : (

    <div>
      <Header data="Candidates List" />
      <a
        style={{ display: 'none' }}
        download={downloadFileName}
        href={downloadDataUriContent}
        ref={anchorDownloadFileRef}
      > hello </a>

      <table className="content-table" responsive="md">
        <thead>
          <tr>
            <th>Rank</th>
            <th>HBITS ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact No</th>
            <th>City</th>
            <th>Date</th>
            <th>Resume</th>
          </tr>
        </thead>
        <tbody>
          {JobSeekerListData.jobSeekerListEntities.slice(pagination.start, pagination.end).map((user, i) => (

            <tr key={i} className="active-row">
              <td>{i + 1}</td>
              <td>{user.HBITS_ID}</td>
              <td>{user.firstname} {user.lastname}</td>
              <td>{user.email}</td>
              <td>{user.phoneno}</td>
              <td>{user.city}</td>
              <td>{moment(user.applydate).format("MMM DD YYYY")}</td>
              <td><Link onClick={download(user.file)} className=" btn btn-primary btn-sm  btncolor" target="_blank" download>Download</Link></td>
            </tr>
          ))}
        </tbody>
      </table>

      <Paginations showPerPage={showPerPage}
        onPaginationChange={onPaginationChange}
        total={Count}
      />
    </div>
  )
}



const mapStateToProps = (state) => {
  return {
    JobSeekerListData: state.jobseeker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchJobSeekerList: () => dispatch(fetchJobSeekerList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Job_seeker_list));

