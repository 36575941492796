//
//
// =====================================================================================
//        MORE THEN ONE REDUCER WE CAN COMBINED HERE USING "COMBINED REDUCER"
// =====================================================================================
//
//

import { combineReducers } from "redux";
import JobListReducer from "./JobList/jobListReducer";
import AuthReducer from "./Auth/AuthReducer";
import JobSeekerListReducer from "./JobSeekerList/jobSeekerListReducer";

// import JobDetailReducer from "./JobDetail/jobDetailReducer";
const rootReducer = combineReducers({
  user: JobListReducer,
  auth: AuthReducer,
  jobseeker: JobSeekerListReducer,
  // emailReceiver: JobDetailReducer,
});

export default rootReducer;
