import React, { useEffect, useState } from 'react';
import { fetchEmailList } from "../../redux/JobList/jobListAction";
import { get as _get } from "lodash";
import { Button, Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { FcBookmark } from "react-icons/fc";
import { IoBriefcase } from "react-icons/io5";
import { HiLocationMarker } from "react-icons/hi";
import { IoLocationSharp } from "react-icons/io5";
import Header from '../../component/Header/Header';
// import { MdWork } from "react-icons/md";
// import ApplyJobForm from '../ApplyJobForm/ApplyJobForm';
import ApplyJobForm from '../../component/ApplyJobForm/ApplyJobForm';
import Paginations from "../../component/paginations/Paginations";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import "../../component/JobList/jobList.scss";
import logo from '../../images/logo.svg';
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Image from 'react-bootstrap/Image'
// import FigureImage from 'react-bootstrap/FigureImage'

import Legend from '../../component/legend/legend'

const moment = require("moment");
moment.suppressDeprecationWarnings = true;

export const JobList = (props) => {
  const [userlogin, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const { EmailReceiverListData } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [id, setid] = useState("");
  const [search, setSearch] = useState("");
  const [searchcity, setSearchCity] = useState("");
  const [showPerPage] = useState(3);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  useEffect(() => {
    dispatch(fetchEmailList());
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [dispatch]);

  //***************15 Days Count Function****************  
  var date2 = moment().format('L');
  var date1 = '';
  const daycount = (requestdate) => {
    date1 = moment((requestdate)).format("MM/DD/YYYY");
    return (new Date(date2) - new Date(date1)) / (1000 * 60 * 60 * 24);
  }


  //******************Set Pagination Start and End Point********************
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end })
  };
  var Count = 0;

  return EmailReceiverListData.loading ? (
    <h2>Loading</h2>
  ) : EmailReceiverListData.error ? (
    <h2>{EmailReceiverListData.error}</h2>
  ) : (
    <>
      <Header data="Job List" />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Job Apply Form</Modal.Title>
        </Modal.Header>
        <ApplyJobForm id={id} />
      </Modal>
      <Container>
        <Card className="searchJob mb-3">
          <Card.Body>
            <Row>
              <Col>
                <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                  Job Search
                    </Form.Label>
                <InputGroup>
                  <FormControl id="inlineFormInputGroup" placeholder="Job Search" onChange={(e) => setSearch(e.target.value)} />
                  <InputGroup.Append>
                    <InputGroup.Text><FaSearch /></InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col>
                <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                  City, State or Zip Code
                    </Form.Label>
                <InputGroup>
                  <FormControl id="inlineFormInputGroup" placeholder="City, State or Zip Code" onChange={(e) => setSearchCity(e.target.value)} />
                  <InputGroup.Append>
                    <InputGroup.Text><IoLocationSharp /></InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>


        <Row>
          <Col lg={12}>
            {/* <hr className="my-3" /> */}
            {EmailReceiverListData.jobListEntities.filter((val) => {
              return (val.positiontitle.toLowerCase().includes(search.toLowerCase())
                && val.physicalWorkAddress.toLowerCase().includes(searchcity.toLowerCase()) && (Count += 1));
            }).slice(pagination.start, pagination.end).map((user, i) => (
              <Card key={i} className="mb-3">
                <Card.Body>
                  <Row className="mb-3">
                    <Col xs>
                      {daycount(user.requestDate) <= 15 ?
                        <>
                          Active Job - Available for {15 - daycount(user.requestDate)} Days
                            </>
                        :
                        <>Expire Job</>
                      }
                    </Col>
                    <Col xs className="text-right">
                      HBITS ID: {_get(user, "HBITS_ID")}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={2} sm={2}>
                      <Image src={logo} fluid />
                    </Col>
                    <Col xs sm>
                      <h4>{user.positiontitle}</h4>
                      <h6>{user.agencyName}</h6>
                      <div>
                        <Legend title={user.physicalWorkAddress} >
                          <HiLocationMarker />
                        </Legend>
                      </div>
                      <Legend title={user.neworExistingPosition} >
                        <FcBookmark />
                      </Legend>
                      <Legend title={user.positionSkill} >
                        <IoBriefcase />
                      </Legend>
                      <Legend title={daycount(user.requestDate) === 0 ? "Today " : <>{daycount(user.requestDate)} Days ago </>} >
                        <FiClock />
                      </Legend>
                    </Col>
                  </Row>
                  <Button variant="primary" href={`/hbits/${_get(user, "HBITS_ID")}`}>Job Details</Button> {' '}
                  <Button variant="primary" disabled={daycount(user.requestDate) <= 15 && userlogin?.result &&
                    (userlogin?.result.role === "User" || userlogin?.result.role === "Recruiter") ? false : true}
                    onClick={(e) => { handleShow(); setid(user.HBITS_ID) }}>Apply Job</Button>
                  {/* {daycount(user.requestDate) <= 15 && userlogin?.result && userlogin?.result.role === "User" ?
                        <Button name="isOpen" onClick={(e) => { handleShow(); setid(user.HBITS_ID) }} variant="primary" >Apply Job</Button>
                        :<Button name="isOpen" disabled variant="primary" >Apply Job</Button>
                      } */}
                </Card.Body>
              </Card>
            ))}
          </Col>
          {/* <Col sm={4}>
            <Card className="mb-3">
              <Card.Body>
                <FigureImage src={logo} className="round-circle" width={60} />
                <Card.Text>
                  “I just got a job that
                  I applied for via HBITS!
                  I used HBITS site all the
                  time during my job hunt.”
                        <div className="font-weight-bold">Lauren Wade</div>
                  <small className="text-muted">New Jearsy, USA</small>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <Card.Title>Create your Resume And Apply Here !</Card.Title>
                <Card.Text>
                  You can Apply available HBITS Job in this Websit.
                  Thnak you for Visiting.
                    </Card.Text>
                <Button href="/">Creat an account</Button>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
      {/* <div className="container">
            <div className="card">
              <div className="card-body qw">
                <div className="row">
                  <div className="col-md-6 col-sm-12 d-flex align-items-center `justify-content-center">
                    <div className="input-group mb-3 position-relative">
                      <input type="text" className="form-control custom-input input-border-color-custom"
                        placeholder="Job Search " aria-label="Recipient's username"
                        aria-describedby="basic-addon2"  />
                      <span className="input-group-text input-border-color-custom" id="basic-addon2"></span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex align-items-center `justify-content-center">
                    <div className="input-group mb-3 position-relative">
                      <input type="text" className="form-control custom-input input-border-color-custom"
                        placeholder="City, State or Zip Code" aria-label="Recipient's username"
                        aria-describedby="basic-addon2" onChange={(e) => setSearchCity(e.target.value)} />
                      <span className="input-group-text input-border-color-custom" id="basic-addon2"><IoLocationSharp /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
      <Paginations showPerPage={showPerPage}
        onPaginationChange={onPaginationChange}
        // total={EmailReceiverListData.jobListEntities.length}
        total={Count}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    EmailReceiverListData: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailList: () => dispatch(fetchEmailList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(JobList));
