import axios from "axios";
import {
  AUTH,
  LOGOUT,
  SIGNUP,
  RECRUITER_SIGNUP,
  RECRUITER_SIGNIN,
} from "./AuthType";
import { getUrl } from "../../Utils";

export const addAuthdata = userobj => {
  return {
    type: AUTH,
    payload: userobj,
  }
}

export const LogOut = userobj => {
  return {
    type: LOGOUT,
    payload: userobj,
  }
}

export const signin = (formData) => {
  return (dispatch) => {
    console.log(formData)
    axios.post(`${getUrl()}/signin`, formData)
      .then(res => {
        console.log(res)
        const result = res.data
        dispatch(signindata(result))
      })
  }
}

export const signindata = (formData) => {
  return {
    type: AUTH,
    payload: formData,
  }
}

export const signup = (formData) => {
  return (dispatch) => {
    axios.post(`${getUrl()}/signup`, formData)
      .then(res => {
        const result = res.data
        dispatch(signupdata(result))
      })
  }
}

export const signupdata = (formData) => {
  return {
    type: SIGNUP,
    payload: formData,
  }
}


export const recruitersignup = (formData) => {
  return (dispatch) => {
    axios.post(`${getUrl()}/recruitersignup`, formData)
      .then(res => {
        const result = res.data
        dispatch(recruitersignupdata(result))
      })
  }
}

export const recruitersignupdata = (formData) => {
  return {
    type: RECRUITER_SIGNUP,
    payload: formData,
  }
}

export const recruitersignin = (formData) => {
  return (dispatch) => {
    console.log(formData)
    axios.post(`${getUrl()}/recruitersignin`, formData)
      .then(res => {
        console.log(res)
        const result = res.data
        dispatch(recruitersignindata(result))
      })
  }
}

export const recruitersignindata = (formData) => {
  return {
    type: RECRUITER_SIGNIN,
    payload: formData,
  }
}