import React, { Fragment, useState, useEffect } from 'react'
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import '../Login/login.scss'
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signin, recruitersignin } from '../../redux/Auth/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'

function Login(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("Select");
    const { ErrorData } = props;
    const [emailErr, setEmailErr] = useState({});
    const [roleErr, setRoleErr] = useState({});


    // const [error, setError] = useState()
    //     const [success, setSuccess] = useState(0)
    useEffect(() => {
        console.log(ErrorData.authData.message)
        if (ErrorData.authData.message === "Invalid credentials.") {
            toast("Invalid credentials.");
        }
        if (ErrorData.authData.message === "success") {
            console.log("success");
            window.location.reload();
        }
    }, [ErrorData.authData.message, history]);


    //******************************Check Form Validation*****************************
    const formValidation = () => {
        const emailErr = {};
        const roleErr = {};
        let isValid = true;

        if (!email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
            emailErr.emailShort = "Please Enter Valid Email Id.";
            isValid = false;
        }

        if (role === "Select") {
            roleErr.select = "Please Select User Role.";
            isValid = false;
        }

        setEmailErr(emailErr);
        setRoleErr(roleErr);

        return isValid;
    }


    //********************Submit Form Data****************************
    const formSubmit = async e => {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            const formData = {
                email: email,
                password: password,
            }
            if (role === "Jobseeker") { await dispatch(signin(formData)) }
            if (role === "Admin" || role === "Recruiter") { await dispatch(recruitersignin(formData)) }
            // history.push("/login")
        }
    }


    return (
        <>
            <Fragment>
                <Container sm={6} md={6}>
                    <ToastContainer />
                    <Row>
                        <Col>
                            <div>
                                <Form onSubmit={formSubmit}>
                                    <ModalBody>
                                        <Col >
                                            <Form.Group>
                                                <Form.Label>Select Role<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control required as="select" value={role} onChange={(e) => setRole(e.target.value)} >
                                                    <option value="Select">Select Role</option>
                                                    {/* <option value="Admin">Admin</option> */}
                                                    <option value="Recruiter">Recruiter</option>
                                                    <option value="Jobseeker">Job seeker</option>
                                                </Form.Control>
                                                {Object.keys(roleErr).map((key) => {
                                                    return <div style={{ color: "red" }}>{roleErr[key]}</div>
                                                })}
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Form.Group>
                                                <Form.Label >Email address</Form.Label>
                                                <Form.Control type="email" title="Enter Your Email Id."
                                                    name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                {Object.keys(emailErr).map((key) => {
                                                    return <div style={{ color: "red" }}>{emailErr[key]}</div>
                                                })}
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Form.Group>
                                                <Form.Label >Password</Form.Label>
                                                <Form.Control type="password" title="Enter Password."
                                                    name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button variant="primary" type="submit">
                                            Submit
                                         </Button>
                                    </ModalFooter>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        ErrorData: state.auth,
    }
}

export default connect(mapStateToProps)(React.memo(Login));