export const Cities = {
    cities: [
        { Key: 1, Value: 'Jersey City' },
        { Key: 2, Value: 'Newark' },
        { Key: 3, Value: 'Trenton' },
        { Key: 4, Value: 'Atlantic City' },
        { Key: 5, Value: 'Paterson' },
        { Key: 6, Value: 'Pronceton' },
        { Key: 7, Value: 'Edison' },
        { Key: 8, Value: 'Toms River' },
        { Key: 9, Value: 'West New York' },
        { Key: 10, Value: 'Hoboken' },
        { Key: 11, Value: 'Wayne' },
        { Key: 12, Value: 'Hackensack' },
        { Key: 13, Value: 'Clifton' },
        { Key: 14, Value: 'Morristown' },
        { Key: 15, Value: 'Cherry Hill' },
        { Key: 16, Value: 'Vineland' },
        { Key: 17, Value: 'Parsippany-Troy Hills' },
        { Key: 18, Value: 'Bayonne' },
        { Key: 19, Value: 'Paramus' },
        { Key: 20, Value: 'Montclair' },
        { Key: 21, Value: 'Hamilton Township' },
        { Key: 22, Value: 'Cape May' },
        { Key: 23, Value: 'Passaic' },
        { Key: 24, Value: 'Piscataway' },
        { Key: 25, Value: 'East Brunsswick' },
        { Key: 26, Value: 'Secaucus' },
        { Key: 27, Value: 'Woodbridge Twonship' },
        { Key: 28, Value: 'Kearny' },
        { Key: 29, Value: 'Bloomfield' },
        { Key: 30, Value: 'Nutley' },
        { Key: 31, Value: 'North Bergen' },
        { Key: 32, Value: 'Perth Amboy' },
        { Key: 33, Value: 'Howell Township' },
        { Key: 34, Value: 'Livingston' },
        { Key: 35, Value: 'Phillipsburg' },
        { Key: 36, Value: 'Old Bridge' },
        { Key: 37, Value: 'Monroe Township' },
        { Key: 38, Value: 'West Orange' },
        { Key: 39, Value: 'Mahwah' },
        { Key: 40, Value: 'East Orange' },
        { Key: 41, Value: 'Manalapan Township' },
        { Key: 42, Value: 'Red Bank' },
        { Key: 43, Value: 'Moorestown' },
        { Key: 44, Value: 'Asbury Park' },
        { Key: 45, Value: 'Mount Laurel Twonship' },
        { Key: 46, Value: 'Voorhees Township' },
        { Key: 47, Value: 'Sayreville' },
        { Key: 48, Value: 'Millville' },
        { Key: 49, Value: 'Teaneck' },
        { Key: 50, Value: 'Fair Lawn' },
        { Key: 51, Value: 'Long Branch' }
    ]
}
export default Cities;