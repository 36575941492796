import axios from "axios";
import { getUrl } from "../../Utils";

import {
  FETCH_JOB_SEEKER_LIST_REQUEST,
  FETCH_JOB_SEEKER_LIST_SUCCESS,
  FETCH_JOB_SEEKER_LIST_FAILURE,
} from "./jobSeekerListType";

export const fetchJobSeekerList = () => {
  return (dispatch) => {
    dispatch(fetchJobSeekerListRequest());
    // axios.get("http://localhost:3001/jobList")
    axios.get(`${getUrl()}/Jobseeker`)
      .then((response) => {
        const JobSeekerListEntities = response.data
        dispatch(fetchJobSeekerListSuccess(JobSeekerListEntities));
      })
      .catch((error) => {
        dispatch(fetchJobSeekerListFailure(error.message));
      });
  };
};


export const fetchJobSeekerListRequest = () => {
  return {
    type: FETCH_JOB_SEEKER_LIST_REQUEST,
  };
};

export const fetchJobSeekerListSuccess = (JobSeekerListEntities) => {
  return {
    type: FETCH_JOB_SEEKER_LIST_SUCCESS,
    payload: JobSeekerListEntities,
  };
};

export const fetchJobSeekerListFailure = (error) => {
  return {
    type: FETCH_JOB_SEEKER_LIST_FAILURE,
    payload: error,
  };
};
