import React from 'react'
import { Form, Button, } from "react-bootstrap";
import { FaFacebook } from "react-icons/fa";
import { FiLinkedin, FiInstagram } from "react-icons/fi";
import { ImGooglePlus } from "react-icons/im";
// import '../Footer/Footer.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'


function Footer() {
    return (
        <React.Fragment>
            <div className="footer">
                <Container>
                    <Row className="text-white">
                        <Col xs={5}>
                            <div className="font-weight-bold mb-2">HBITS</div>
                            <p>Subscribe to our Email Post service to never missany new job updates.</p>
                            <Form>
                                <Form.Row>
                                    <Col>
                                        <Form.Control type="email" placeholder="Your email address" />
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="primary">Subscribe</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                            <div className="my-3">
                                <Button as="a" variant="link" className="text-white">
                                    <h4>
                                        <FaFacebook />
                                    </h4>
                                </Button>
                                <Button as="a" variant="link" className="text-white">
                                    <h4>
                                        <FiLinkedin />
                                    </h4>
                                </Button>
                                <Button as="a" variant="link" className="text-white">
                                    <h4>
                                        <FiInstagram />
                                    </h4>
                                </Button>
                                <Button as="a" variant="link" className="text-white">
                                    <h4>
                                        <ImGooglePlus />
                                    </h4>
                                </Button>
                            </div>
                        </Col>
                        <Col xs>
                            <div className="font-weight-bold mb-2">Frequantly asked question</div>
                            <Row>
                                <Col>
                                    <Nav className="flex-column">
                                        <Nav.Link className="text-white">About Us</Nav.Link>
                                        <Nav.Link className="text-white">Our Blogs</Nav.Link>
                                        <Nav.Link className="text-white">Chat with us</Nav.Link>
                                        <Nav.Link className="text-white">Contact Us</Nav.Link>
                                        <Nav.Link className="text-white">Our Privacy</Nav.Link>
                                    </Nav>
                                </Col>
                                <Col>
                                    <Nav className="flex-column">
                                        <Nav.Link className="text-white">Who are we?</Nav.Link>
                                        <Nav.Link className="text-white">Why HBITS?</Nav.Link>
                                        <Nav.Link className="text-white">Support</Nav.Link>
                                        <Nav.Link className="text-white">Refferal</Nav.Link>
                                        <Nav.Link className="text-white">Security</Nav.Link>
                                    </Nav>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <div className="font-weight-bold mb-2">Find Jobs</div>
                            <Nav className="flex-column">
                                <Nav.Link className="text-white">India Jobs</Nav.Link>
                                <Nav.Link className="text-white">US Jobs</Nav.Link>
                                <Nav.Link className="text-white">UK Jobs</Nav.Link>
                                <Nav.Link className="text-white">Canada Jobs</Nav.Link>
                                <Nav.Link className="text-white">Other Jobs</Nav.Link>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="copyright">
                © Copyright2021By GreyCell Designs All Rights Reserved.
        </div>
        </React.Fragment>
    )
}


export default Footer
