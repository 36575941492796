import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, Modal, Card } from "react-bootstrap";
import { get as _get } from "lodash";
import { connect, useDispatch } from "react-redux";
import { HiLocationMarker } from "react-icons/hi";
import { FcBookmark } from "react-icons/fc";
import { IoBriefcase } from "react-icons/io5";
// import { IoIosMail } from "react-icons/io";
import { FiClock } from "react-icons/fi";
import Header from '../../component/Header/Header';
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'
// import FigureImage from 'react-bootstrap/FigureImage'

// import logo from '../../images/logo.svg';

import { fetchEmailList } from "../../redux/JobList/jobListAction";
import ApplyJobForm from '../../component/ApplyJobForm/ApplyJobForm';

import Legend from '../../component/legend/legend';
// import ApplyJobForm from '../ApplyJobForm/ApplyJobForm';
// import "../../component/JobDetails/jobDeatils.scss";

const moment = require("moment");
moment.suppressDeprecationWarnings = true;


export const JobDetails = (props) => {
  const [userlogin, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { EmailReceiverListData } = props;
  const id = props.match.params.id;

  useEffect(() => {
    dispatch(fetchEmailList());
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [dispatch]);


  //***************15 Days Count Function****************  
  var date2 = moment().format('L');
  var date1 = '';
  const daycount = (requestdate) => {
    date1 = moment(requestdate).format("MM/DD/YYYY");
    return (new Date(date2) - new Date(date1)) / (1000 * 60 * 60 * 24);
  }


  return EmailReceiverListData.loading ? (
    <h2>Loading</h2>
  ) : EmailReceiverListData.error ? (
    <h2>{EmailReceiverListData.error}</h2>
  ) : (
    <>
      <Header data="Job Details" />
      <Container>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Job Apply Form</Modal.Title>
          </Modal.Header>
          <ApplyJobForm id={id} />
        </Modal>
        <Row className="mt-3" >
          <Col md={8}>
            {EmailReceiverListData.jobListEntities.filter(data => data.HBITS_ID === id).map((emailReceiver, i) => (
              <div key={i}>
                <Row className="align-items-center">
                  <Col xs>
                    <h3>{_get(emailReceiver, 'positiontitle')}</h3>
                    <div className="font-weight-bold">{_get(emailReceiver, 'agencyName')}</div>
                  </Col>
                  <Col xs="auto">
                    <Button variant="primary" disabled={daycount(emailReceiver.requestDate) <= 15 && userlogin?.result && userlogin?.result.role === "User" ? false : true} onClick={handleShow}>Apply Job</Button>
                  </Col>
                </Row>
                <div className="mb-2">
                  <Legend title={_get(emailReceiver, 'physicalWorkAddress')}>
                    <HiLocationMarker />
                  </Legend>
                  <Legend title={_get(emailReceiver, 'neworExistingPosition')}>
                    <FcBookmark />
                  </Legend>
                  <Legend title={_get(emailReceiver, 'positionSkill')}>
                    <IoBriefcase />
                  </Legend>
                  <Legend title={daycount(emailReceiver.requestDate) === 0 ? "Today " : <>{daycount(emailReceiver.requestDate)} Days ago </>} >
                    <FiClock />
                  </Legend>
                </div>
                <hr />
                <div className="mb-2">
                  <h5>Provide Short  Description</h5>
                  <hr />
                  {_get(emailReceiver, 'provideShortDesc') &&
                    _get(emailReceiver, 'provideShortDesc').split(/\. /).map((dd, j1) => dd.trim() &&
                      <p key={j1}>
                        {dd}.
                        </p>
                    )
                  }
                </div>

                <div className="mb-2">
                  <h5>Program Task</h5>
                  <hr />
                  <ListGroup variant="flush">
                    {emailReceiver.programTask !== undefined &&
                      emailReceiver.programTask !== null &&
                      Object.values(emailReceiver.programTask).map((PT, jj) =>
                        <ListGroup.Item key={jj}>
                          {PT.split(/\n/).map((dd, j1) =>
                            <div key={j1}>
                              {
                                dd.length > 3 &&
                                <React.Fragment>{dd}</React.Fragment>
                              }
                            </div>
                          )}
                        </ListGroup.Item>
                      )}
                  </ListGroup>
                </div>

                <div className="mb-3">
                  <h5>Request Qualification Number</h5>
                  <hr />
                  <ListGroup variant="flush">
                    {
                      _get(emailReceiver, 'requestQualificationNumber') !== undefined &&
                      _get(emailReceiver, 'requestQualificationNumber') !== null &&
                      Object.values(_get(emailReceiver, 'requestQualificationNumber')).map((data, j) =>
                        data.trim() &&
                        <ListGroup.Item>
                          {data}
                        </ListGroup.Item>
                      )}
                  </ListGroup>
                </div>
                <div className="mb-3">
                  <div className="font-weight-bold">Software Used By Agency</div>
                  <h5>
                    {_get(emailReceiver, 'softwareUsedByAgency').split(/,/).map((dd, j1) =>
                      <React.Fragment>
                        <Badge pill variant="primary" key={j1}>
                          {dd}
                        </Badge>{' '}
                      </React.Fragment>
                    )}
                  </h5>
                </div>
              </div>
            ))}
          </Col>
          <Col sm={4}>
            {EmailReceiverListData.jobListEntities.filter(data => data.HBITS_ID === id).map((emailReceiver, i) => (
              <Card>
                <Card.Body>
                  <Card.Title>Job Details</Card.Title>
                  <Card.Text>
                    <div className="font-weight-bold">Company Name</div>
                    <p>{_get(emailReceiver, 'agencyName')}</p>
                    <div className="font-weight-bold">Job ID</div>
                    <p>
                      {_get(emailReceiver, 'HBITS_ID')}
                    </p>
                    <div className="font-weight-bold">Work Hours</div>
                    <p>
                      {_get(emailReceiver, 'dailyWorkHrs')}
                    </p>
                    {/* <div className="font-weight-bold">Education</div> */}

                    <div className="font-weight-bold">Contact Person</div>
                    <p>
                      {_get(emailReceiver, 'agencyContactPerson')}
                    </p>
                    <div className="font-weight-bold">Company Info</div>
                    <p>
                      {_get(emailReceiver, 'agencyContactEmail')}
                    </p>
                  </Card.Text>
                  {/* <Card.Text>
                    <Row>
                      <Col xs>
                        <small><IoShareSocialOutline className="shareicone" />Share</small>
                      </Col>
                      <Col xs>
                        <small> <IoIosMail className="shareicone" />Send me jobs like this</small>
                      </Col>
                    </Row>
                  </Card.Text> */}
                </Card.Body>
              </Card>
            ))}
            <br />
            {/* <Card className="mb-3">
              <Card.Body>
                <FigureImage src={logo} className="round-circle" width={60} />
                <Card.Text>
                  “I just got a job that
                  I applied for via HBITS!
                  I used HBITS site all the
                  time during my job hunt.”
                      <div className="font-weight-bold">Lauren Wade</div>
                  <small className="text-muted">New Jearsy, USA</small>
                </Card.Text>
              </Card.Body>
            </Card> */}
            {/* <Card className="mb-3">
              <Card.Body>
                <Card.Text>
                  “HBITS is amazing I applied
                  easily and received call
                  from Company and I got
                  Job easily.”
                      <div className="font-weight-bold">Robert Kyle</div>
                  <small className="text-muted">California, USA</small>
                </Card.Text>
              </Card.Body>
            </Card> */}
            {/* <Card className="mb-3">
              <Card.Body>
                <Card.Text>
                  “Expereince is amazing and
                  everything is so clarified in
                  the description and easy to
                  navigate through.”
                      <div className="font-weight-bold">Jane shaw</div>
                  <small className="text-muted">Nevada, USA</small>
                </Card.Text>
              </Card.Body>
            </Card> */}
            {/* <Card className="mb-3">
                <Card.Body>
                  <Card.Text>
                    “Expereince is amazing and
                    everything is so clarified in
                    the description and easy to
                    navigate through.”
                      <div className="font-weight-bold">Jane shaw</div>
                      <small className="text-muted">Nevada, USA</small>
                    </Card.Text>
                </Card.Body>
              </Card> */}
          </Col>
        </Row>
        {/* <CardDeck className="mb-3">
          <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2" >Programmer</Card.Subtitle>
              <Card.Title>Department of Public Services</Card.Title>
              <Card.Text>
                <p>
                  <HiLocationMarker />{' '}New Jersey, USA
                  </p>
                <p>Microsoft .NET, SQL Server, Angular, SSRS, GIS</p>
              </Card.Text>
              <Card.Text>
                <small>Posted 2 days ago</small>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2" >IT Specialist</Card.Subtitle>
              <Card.Title>Infinity IT Department Services</Card.Title>
              <Card.Text>
                <p>
                  <HiLocationMarker />{' '}California, USA
                  </p>
                <p>C# Language, SQL Server, Oracle, .Net, FileNet</p>
              </Card.Text>
              <Card.Text>
                <small>Posted 5 days ago</small>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2" >Web Designer</Card.Subtitle>
              <Card.Title>H-Cross Public Services</Card.Title>
              <Card.Text>
                <p>
                  <HiLocationMarker />{' '}Los Angelos, USA
                  </p>
                <p>Adobe XD, HTML, CSS, JavaScript, UI-UX, PHP</p>
              </Card.Text>
              <Card.Text>
                <small>Posted 7 days ago</small>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2" >Laravel Devloper</Card.Subtitle>
              <Card.Title>Angular Product Services</Card.Title>
              <Card.Text>
                <p>
                  <HiLocationMarker />{' '}Washington, USA
                  </p>
                <p>SSAS, SSIS, SQL Server, Angular, SSRS, GIS</p>
              </Card.Text>
              <Card.Text>
                <small>Posted 13 days ago</small>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardDeck> */}
      </Container>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    EmailReceiverListData: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailList: () => dispatch(fetchEmailList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(JobDetails));



