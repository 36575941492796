import {
  AUTH,
  LOGOUT,
  SIGNUP,
  RECRUITER_SIGNUP,
  RECRUITER_SIGNIN,
} from "./AuthType";


const initialState = {
  // loading: false,
  authData: [],
  error: "",
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH:
      console.log(action.payload)
      localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
      return {
        ...state, authData: action.payload,
      };
    case LOGOUT:
      localStorage.clear();
      return {
        ...state, authData: action.payload,
      };
    case SIGNUP:
      return {
        ...state, authData: action.payload,
      };
    case RECRUITER_SIGNUP:
      return {
        ...state, authData: action.payload,
      };
    case RECRUITER_SIGNIN:
      console.log(action.payload)
      localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
      return {
        ...state, authData: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
