import React, { useEffect, useState } from 'react'
// import { Table } from "react-bootstrap";
import './applyjoblist.scss'
import Header from '../../component/Header/Header';
// import { FcDownload } from "react-icons/fc";
// import ReactTable from "react-table";
import { fetchJobSeekerList } from "../../redux/JobSeekerList/jobSeekerListAction";
import { connect, useDispatch } from "react-redux";
import Paginations from "../../component/paginations/Paginations"
import moment from "moment-timezone";
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import { FaSearch } from "react-icons/fa";
import Container from 'react-bootstrap/Container'

const Applyjoblist = (props) => {
  const [userr, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [search, setSearch] = useState("");
  const [showPerPage] = useState(3);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const dispatch = useDispatch();
  //   const location = useLocation();
  const { JobSeekerListData } = props;
  useEffect(() => {
    dispatch(fetchJobSeekerList());
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [dispatch]);

  console.log("user", userr.result._id)
  console.log("JobSeekerListData", JobSeekerListData.jobSeekerListEntities)

  //******************Set Pagination Start and End Point********************
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end })
  };
  var Count = JobSeekerListData.jobSeekerListEntities.length;

  return JobSeekerListData.loading ? (
    <h2>Loading</h2>
  ) : JobSeekerListData.error ? (
    <h2>{JobSeekerListData.error}</h2>
  ) : (

    <div>
      <Header data="Apply Job List" />
      <Container>
        <Card className="searchJob mb-12">
          <Card.Body>
            <Row >
              <Col>
                <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                  Job Search
                    </Form.Label>
                <InputGroup>
                  <FormControl id="inlineFormInputGroup" placeholder="Job Data" onChange={(e) => setSearch(e.target.value)} />
                  <InputGroup.Append>
                    <InputGroup.Text><FaSearch /></InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <table className="content-table" responsive="md">
          <thead>
            <tr>
              <th>Rank</th>
              <th>HBITS ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact No</th>
              <th>City</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {JobSeekerListData.jobSeekerListEntities.filter((val) => {
              return (val.user_id.toLowerCase().includes(userr.result._id.toLowerCase()) &&
                ((val.HBITS_ID.toLowerCase().includes(search.toLowerCase())) ||
                  (val.firstname.toLowerCase().includes(search.toLowerCase())) ||
                  (val.lastname.toLowerCase().includes(search.toLowerCase())) ||
                  (val.email.toLowerCase().includes(search.toLowerCase())) ||
                  (val.phoneno.toLowerCase().includes(search.toLowerCase())))
              );
            }).slice(pagination.start, pagination.end).map((user, i) => (

              <tr key={i} className="active-row">
                <td>{i + 1}</td>
                <td>{user.HBITS_ID}</td>
                <td>{user.firstname} {user.lastname}</td>
                <td>{user.email}</td>
                <td>{user.phoneno}</td>
                <td>{user.city}</td>
                <td>{moment(user.applydate).format("MMM DD YYYY")}</td>

              </tr>

            ))}
          </tbody>
        </table>
        <Paginations showPerPage={showPerPage}
          onPaginationChange={onPaginationChange}
          // total={EmailReceiverListData.jobListEntities.length}
          total={Count}
        />
      </Container>
    </div>
  )
}



const mapStateToProps = (state) => {
  return {
    JobSeekerListData: state.jobseeker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchJobSeekerList: () => dispatch(fetchJobSeekerList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Applyjoblist));
