import React, { useState, useEffect } from 'react'
// import '../Header/Header.scss'
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Image from 'react-bootstrap/Image'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';
// import Figure from 'react-bootstrap/Figure'
// import Card from 'react-bootstrap/Card'
import { LogOut } from '../../redux/Auth/AuthAction';
// import Card from 'react-bootstrap/Card'
import Modal from "react-bootstrap/Modal";
import Singup from '../SignUp/Singup'
import RecruiterSignup from "../SignUp/Recruiter_Signup";
import Login from '../Login/Login'

export default function Header(props) {
	const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
	// console.log(user);
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()

	const [show, setShow] = useState(false);
	const [showAdd, setShowAdd] = useState(false);
	const [showlogin, setShowlogin] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleCloseAdd = () => setShowAdd(false);
	const handleShowAdd = () => setShowAdd(true);
	const handleCloselogin = () => setShowlogin(false);
	const handleShowlogin = () => { setShowlogin(true) };

	const logout = () => {
		const logoutdata = null;
		dispatch(LogOut({ logoutdata }));
		history.push('/hbits')
		// setUser(" ")
	}

	useEffect(() => {
		// const token = user?.token;
		setUser(JSON.parse(localStorage.getItem('profile')));
	}, [location]);

	return (

		<React.Fragment>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Job Seeker Sign-Up</Modal.Title>
				</Modal.Header>
				<Singup />
			</Modal>
			<Modal show={showlogin} onHide={handleCloselogin}>
				<Modal.Header closeButton>
					<Modal.Title>Login</Modal.Title>
				</Modal.Header>
				<Login />
			</Modal>
			<Modal show={showAdd} onHide={handleCloseAdd}>
				<Modal.Header closeButton>
					<Modal.Title>Recruiter Sign-Up</Modal.Title>
				</Modal.Header>
				<RecruiterSignup />
			</Modal>
			<Container>
				<Row className="align-items-center">
					<Col xs={6} sm={1} className="text-center text-sm-left">
						<Navbar.Brand href="/hbits"><img width={70} src="../images/logo.svg" className="image_style" alt="" /></Navbar.Brand>
						{/* <Figure className="mb-0">
							<Figure.Image width={100} onClick="/" src="../images/logo.svg" className="mb-0" />
						</Figure> */}
						{/* <Image  fluid /> */}
					</Col>
					<Col xs lg>
						<Navbar>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								{/* <Button id="btnDrop1" type="button" >HOME</Button> */}
								<Nav variant="pills" className="mx-auto" >


									<NavDropdown title="FOR CANDIDATES" id="basic-nav-dropdown" className="dropespace">
										{user?.result && user?.result.role === "User" ?
											<NavDropdown.Item href="/hbits/applyjoblist">Apply Job List</NavDropdown.Item> :
											<NavDropdown.Item onClick={() => handleShowlogin()}>Apply Job List</NavDropdown.Item>
										}
									</NavDropdown>

									{user?.result && user?.result.role === "Recruiter" &&
										<NavDropdown title="FOR EMPLOYERS" id="basic-nav-dropdown" className="dropespace">
											{/* <NavDropdown.Item onClick={() => handleShowAdd()}>Add Recruiter</NavDropdown.Item> */}
											<NavDropdown.Item href="/hbits/Jobseeker">Candidates List</NavDropdown.Item>
										</NavDropdown>
									}

									<Nav.Link href="/hbits" >ABOUT US</Nav.Link>&nbsp;

									<Nav.Link href="/hbits">CONTACT US</Nav.Link>

								</Nav>

							</Navbar.Collapse>
						</Navbar>
					</Col>
					<Col xs lg="auto">
						{user?.result ?
							<>
								{user?.result.firstname}&nbsp;{user?.result.lastname}&nbsp;
										<Button id="btnDrop1" type="button" onClick={logout} className=" btn-primary marg " >Logout</Button>
							</>
							:
							<>
								<NavDropdown title="Sign up" id="basic-nav-dropdown" className="dropespace">
									<NavDropdown.Item id="btnDrop1" variant="primary" className="margin" onClick={() => handleShow()}>Job Seeker</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleShowAdd()}>Recruiter</NavDropdown.Item>
								</NavDropdown>
								<Nav.Link id="btnDrop2" variant="primary" onClick={() => handleShowlogin()}>Login</Nav.Link>
							</>
						}
					</Col>
				</Row>
			</Container>
			<div className="banner">
				<div className="banner-slogan">{props.data}</div>
				{/* <Card.Img variant="bottom" src="../images/meet.jpg" /> */}
			</div>
			{/* <div className="container-xxl">
					<div className="header-parent">
						<div className="row justify-content-center text-center">
							<div className="col col-lg-8">
								<div className="header-slogan my-5">
									<strong><h3>BROWES JOB LIST</h3></strong>
								</div>
							</div>
							<div className="w-100"></div>
						</div>
					</div>
				</div> */}
		</React.Fragment>

	)
}
