import axios from "axios";
import { getUrl } from "../../Utils";

import {
  FETCH_JOB_APPLY_REQUEST,
  FETCH_JOB_APPLY_SUCCESS,
  FETCH_JOB_APPLY_FAILURE,
  FETCH_JOB_ASSIGN_SUCCESS,
} from "./JobApplyType";

export const addjobapplydata = userobj => {
  return (dispatch) => {
    dispatch(fetchjobapplyRequest());
    // axios.post('http://localhost:3001/jobapplydata', (userobj))
    axios.post(`${getUrl()}/jobapplydata`, (userobj))

      .then(response => {
        const JobApplyEntities = response.data
        dispatch(fetchjobapplySuccess(JobApplyEntities));
      })
      .catch(error => {
        dispatch(fetchjobapplyFailure(error.message));
      })
  }
}

export const uploadresume = userobj => {
  return {
    type: FETCH_JOB_APPLY_SUCCESS,
    // payload: axios.post('http://localhost:3001/upload', userobj)
    payload: axios.post(`${getUrl()}/upload`, userobj)

  }
}

export const applyjobapplydata = userobj => {
  return {
    type: FETCH_JOB_ASSIGN_SUCCESS,
    payload: axios.post(`${getUrl()}/assigncandidate`, userobj)

  }
}

export const fetchjobapplyRequest = () => {
  return {
    type: FETCH_JOB_APPLY_REQUEST,
  };
};

export const fetchjobapplySuccess = (JobApplyEntities) => {
  return {
    type: FETCH_JOB_APPLY_SUCCESS,
    payload: JobApplyEntities,
  };
};

export const fetchjobapplyFailure = (error) => {
  return {
    type: FETCH_JOB_APPLY_FAILURE,
    payload: error,
  };
};
