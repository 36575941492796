import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addjobapplydata, uploadresume } from '../../redux/JobApply/JobApplyAction';
// import "../ApplyJobForm/applyjobform.scss";
import { useDispatch } from "react-redux";
// import axios from "axios";
// import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Cities } from "../City-List/cities";

import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'

const ApplyJobForm = (props) => {

    // let history = useHistory();
    const [userlogin, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch();
    const imageInputRef = React.useRef();
    const [firstname, setFirstname] = useState(userlogin?.result.firstname);
    const [lastname, setLastname] = useState(userlogin?.result.lastname);
    const [email, setEmail] = useState(userlogin?.result.email);
    const [phoneno, setPhoneno] = useState(userlogin?.result.phoneno);
    const [address, setAddress] = useState(userlogin?.result.address);
    const [pinecode, setPinecode] = useState(userlogin?.result.pinecode);
    const [city, setCity] = useState(userlogin?.result.city);
    const [state, setState] = useState("New Jersey");
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState("");
    const [country, setCountry] = useState("USA");

    const [firstnameErr, setFirstnameErr] = useState({});
    const [lastnameErr, setLastnameErr] = useState({});
    // const [emailErr, setEmailErr] = useState({});
    // const [cityErr, setCityErr] = useState({});
    // const [phonenoErr, setPhonenoErr] = useState({});
    // const [addressErr, setAddressErr] = useState({});
    // const [pinecodeErr, setPinecodeErr] = useState({});
    const [fileErr, setFileErr] = useState({});

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')));
    }, []);

    // if(userlogin?.result.role === "User"){
    //     setFirstname(userlogin.result.firstname)
    //     setLastname(userlogin.result.firstname)
    // }

    //**************************Set File Data***********************
    const onChange = (e) => {
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
    }

    //*************************ReSet Form************************
    const reset = () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setPhoneno("");
        setAddress("");
        setCity("");
        setPinecode("");
        setFirstnameErr("");
        setLastnameErr("");
        // setAddressErr("");
        // setPinecodeErr("");
        // setEmailErr("");
        // setPhonenoErr("");
        imageInputRef.current.value = "";
        setFile(null);
    }

    //******************************Check Form Validation*****************************
    const formValidation = () => {
        const firstnameErr = {};
        const lastnameErr = {};
        // const emailErr = {};
        // const phonenoErr = {};
        // const addressErr = {};
        // const cityErr = {};
        // const pinecodeErr = {};
        const fileErr = {};
        let isValid = true;

        if (!firstname.match(/[A-Za-z]+$/)) {
            firstnameErr.firstNameShort = "Please Enter Only Alphabets.";
            isValid = false;
        }

        if (!lastname.match(/[A-Za-z]+$/)) {
            lastnameErr.lastNameShort = "Please Enter Only Alphabets.";
            isValid = false;
        }

        // if (!email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
        //     emailErr.emailShort = "Please Enter Valid Email Id.";
        //     isValid = false;
        // }

        // if (!phoneno.match(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/)) {
        //     phonenoErr.pnShort = "Please Enter only 10 digits.";
        //     isValid = false;
        //     // 123 456 7890  ||  (123)456 7890  ||  (123) 456 7890  ||  (123)456-7890
        //     //(123) 456-7890 ||  123.456.7890   ||  123-456-7890
        // }

        // if (!address.match(/^[#./0-9a-zA-Z\s,-]+$/)) {
        //     addressErr.addressShort = "Please Enetr Only Alphabets, Digits and , . / # - ";
        //     isValid = false;
        // }

        // if (city === null) {
        //     cityErr.cityselect = "Please Select City";
        //     isValid = false;
        // }

        // if (!pinecode.match(/^[0-9]{5}$/)) {
        //     pinecodeErr.pinecodeShort = "Please Enter only digits.";
        //     isValid = false;
        // }

        if (file === null) {
            fileErr.fileselect = "Please Select File";
            isValid = false;
        }

        setFirstnameErr(firstnameErr);
        setLastnameErr(lastnameErr);
        // setEmailErr(emailErr);
        // setPhonenoErr(phonenoErr);
        // setPinecodeErr(pinecodeErr);
        // setAddressErr(addressErr);
        // setCityErr(cityErr);
        setFileErr(fileErr);

        return isValid;
    }

    //********************Submit Form Data****************************
    const formSubmit = async e => {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            const newJobApply = {
                HBITS_ID: props.id,
                firstname: firstname,
                lastname: lastname,
                email: email,
                phoneno: phoneno,
                address: address,
                pinecode: pinecode,
                city: city,
                state: state,
                country: country,
                file: filename,
                userid: userlogin.result._id
            }
            console.log(newJobApply);
            const fd = new FormData();
            fd.append("file", file);
            // await axios.post('http://localhost:3001/jobapplydata', fd)
            // axios.post('http://localhost:3001/upload', filedata)
            await dispatch(addjobapplydata(newJobApply));
            dispatch(uploadresume(fd));
            toast("SuccessFully Save Your Data. Please Check Your Mail.");
            reset();
            // history.push("/download");
        }
    }
    return (
        <form onSubmit={formSubmit}>
            <ModalBody>
                <ToastContainer />
                <div className="form-row">
                    <div className="col">
                        <label>First name</label>
                        <input type="text" required className="form-control" title="Enter Only Alphabets [A-Z] or [a-z]"
                            name="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                        {Object.keys(firstnameErr).map((key) => {
                            return <div style={{ color: "red" }}>{firstnameErr[key]}</div>
                        })}
                    </div>
                    <div className="col">
                        <label className=" minu">Last name</label>
                        <input type="text" required className="form-control" title="Enter Only Alphabets [A-Z] or [a-z]"
                            name="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                        {Object.keys(lastnameErr).map((key) => {
                            return <div style={{ color: "red" }}>{lastnameErr[key]}</div>
                        })}
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <label>Email Id</label>
                        <input type="text" required className="form-control" title="Enter Your Email Id."
                            name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        {/* {Object.keys(emailErr).map((key) => {
                                return <div style={{ color: "red" }}>{emailErr[key]}</div>
                            })} */}
                    </div>
                    <div className="col">
                        <label>Phone Number</label>
                        <input type="text" required className="form-control" title="Enter 123 456 7890"
                            name="phoneno" value={phoneno} onChange={(e) => setPhoneno(e.target.value)} />
                        {/* {Object.keys(phonenoErr).map((key) => {
                                return <div style={{ color: "red" }}>{phonenoErr[key]}</div>
                            })} */}
                    </div>
                </div><br></br>
                <div className="form-row">
                    <div className="col">
                        <label>Address</label>
                        <input type="text" required className="form-control" title="Enetr Only Alphabets, Digits and , . / # - "
                            name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        {/* {Object.keys(addressErr).map((key) => {
                                return <div style={{ color: "red" }}>{addressErr[key]}</div>
                            })} */}
                    </div>
                </div><br></br>
                <div className="form-row">
                    <div className="col">
                        <label>City</label>
                        <input type="text" required className="form-control" title="Enetr Only Alphabets"
                            name="address" value={city} onChange={(e) => setCity(e.target.value)} />
                        {/* <select value={city} onChange={(e) => setCity(e.target.value)} className="form-control"> */}
                        {/* <option value="Select"></option> */}
                        {/* {Cities.cities && Cities.cities.map((e, key) => {
                                    return <option key={key} value={e.Value}>{e.Value}</option>;
                                })} */}
                        {/* </select> */}
                        {/* {Object.keys(cityErr).map((key) => {
                                return <div style={{ color: "red" }}>{cityErr[key]}</div>
                            })} */}
                    </div>
                    <div className="col">
                        <label>Zip code</label>
                        <input type="text" required className="form-control" title="Please Enetr Only Digits. "
                            name="pinecode" value={pinecode} onChange={(e) => setPinecode(e.target.value)} />
                        {/* {Object.keys(pinecodeErr).map((key) => {
                                return <div style={{ color: "red" }}>{pinecodeErr[key]}</div>
                            })} */}
                    </div>
                </div><br></br>
                <div className="form-row">
                    <div className="col">
                        <label>State</label>
                        <input type="text" disabled required className="form-control"
                            name="state" value={state} onChange={(e) => setState(e.target.value)} />
                    </div>
                    <div className="col">
                        <label>Country</label>
                        <input type="text" disabled required className="form-control"
                            name="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                    </div>
                </div><br></br>
                <div className="form-group">
                    <label>Upload Resume</label>
                    <input type="file" required className="form-control-file" id="exampleFormControlFile1"
                        name="file" onChange={onChange} ref={imageInputRef} />
                    {Object.keys(fileErr).map((key) => {
                        return <div style={{ color: "red" }}>{fileErr[key]}</div>
                    })}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" variant="primary">Submit</Button>
                <Button type="reset" variant="primary" onClick={reset}>Reset</Button>
                {/* <button type="submit" className="btn btn-primary mb-2">Submit</button>
                <button type="button"  className="btn btn-primary mb-2">Reset</button> */}
            </ModalFooter>
        </form>

    );
}
const mapStateToProps = (state) => {
    return {
        datajob: state.user,
    };
};

export default connect(mapStateToProps, { addjobapplydata, uploadresume })(ApplyJobForm);
